import { Typography, Box, useTheme, CircularProgress } from "@mui/material";
import { tokens } from "../theme";
import { colors as color } from "../assets/css/colors";
import { useSelector } from "react-redux";

const Header = ({ title, subtitle }) => {
  const { isLoading } = useSelector((state) => state.app);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      mb="10px"
      mt={"-10px"}
      sx={{
        paddingLeft: 1,
        marginBottom: 0,
        backgroundColor: "",
        width: "100%",
      }}
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box marginRight={2} sx={{ backgroundColor: "", minWidth: 300 }}>
          <Typography variant="h3" color={colors.grey[100]} fontWeight="bold">
            {title}
          </Typography>
          <Typography variant="h6" color={colors.greenAccent[400]}>
            {subtitle}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "",
            width: "100%",
            justifyContent: "flex-end",
            // pl: 2,
            mr: 1,
          }}
        >
          {isLoading && (
            <CircularProgress
              sx={{ color: color.primary }}
              size={35}
              thickness={5}
            />
          )}
        </Box>
      </Box>
      {/* <Typography variant="h3" color={colors.grey[100]} fontWeight="bold">
        {title}
      </Typography>
      <Typography variant="h6" color={colors.greenAccent[400]}>
        {subtitle}
      </Typography> */}
    </Box>
  );
};

export default Header;
