import React, { useEffect, useState } from "react";
import {
  Paper,
  Box,
  Grid,
  CssBaseline,
  Typography,
  CircularProgress,
} from "@mui/material";
import Image from "mui-image";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import logo_main from "../../../assets/image/logo_main.png";
import login_background from "../../../assets/image/login_background.jpg";
import { useSelector, useDispatch } from "react-redux";
import { colors } from "../../../assets/css/colors";
import { logOut } from "../../../store/slices/user";

const defaultTheme = createTheme();

export const PersonalData = () => {
  const dispatch = useDispatch();
  const { isLogged } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(true);

  const init = async () => {
    if (isLogged) await dispatch(logOut());
    setLoading(false);
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Text = ({ variant, text, marginTop = 10 }) => {
    return (
      <Typography
        variant={variant}
        color="text.secondary"
        align="center"
        textAlign={"justify"}
        style={{
          position: "relative",
          color: "black",
          marginTop: marginTop,
        }}
      >
        {text}
      </Typography>
    );
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Grid
        container
        component="main"
        // sx={{ height: "100vh", justifyContent: "center" }}
        sx={{
          justifyContent: "center",
          backgroundImage: `url(${login_background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            padding: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 10,
            width: "100%",
          }}
          component={Paper}
          elevation={6}
        >
          <Image
            src={logo_main}
            duration={0}
            height={250}
            width={250}
            fit={"contain"}
            style={{ marginBottom: 0, backgroundColor: "" }}
          />

          {loading && (
            <Box sx={{ mt: 5, height: "40vh" }}>
              <CircularProgress style={{ color: colors.primary }} />
            </Box>
          )}

          {!loading && (
            <Box sx={{ mt: 2 }}>
              <Typography
                variant="h4"
                color="text.secondary"
                align="center"
                style={{
                  position: "relative",
                  color: "black",
                }}
              >
                Política de Privacidad y Protección de Datos Personales
              </Typography>
              <Text
                text={`La presente Política de Privacidad tiene por finalidad informar a los usuarios del tratamiento de los 
              datos personales que se recogen a través la aplicación Rancagua si existe y su respectica APP en cumplimiento 
              con la Ley de protección de datos personales.`}
                variant={"body2"}
                marginTop={25}
              />
              <Text
                text={`RESPONSABILIDAD DE RANCAGUA SI EXISTE POR USO DE DATOS`}
                variant={"body1"}
                marginTop={35}
              />
              <Text
                text={`Rancagua si existe adoptará las medidas de seguridad necesarias para garantizar la confidencialidad de los datos por medio de formularios, encuestas o mecanismos de registro de navegación web. Rancagua si existe y sus funcionarios que en cualquier momento pudiesen interactuar con datos de carácter personal están obligados a guardar secreto de los mismos, cuando provengan o hayan sido recolectados de fuentes no accesibles al público; obligaciones que subsistirán indefinidamente, aún después de finalizar sus relaciones con la aplicación.`}
                variant={"body2"}
              />
              <Text
                text={`Los datos entregados por los usuarios a su APP  serán administrados exclusivamente por personal de Rancagua si existe, evitando usos indebidos, alteración o entrega a terceros. Sin perjuicio de lo anterior, Rancagua si existe no se hará responsable del uso que puedan dar terceras personas a los datos personales entregados por sus titulares en espacios abiertos al público, como redes sociales o foros.`}
                variant={"body2"}
              />
              <Text
                text={`En el caso que, mediante contrato de servicio, sea una entidad externa o empresa quien administre las bases de datos que se recogen a través de su APP Rancagua si existe suscribirá un acuerdo de confidencialidad, de manera que bajo ninguna circunstancia la empresa o entidad pueda comercializar, utilizar ni entregar datos personales de los usuarios a terceros.`}
                variant={"body2"}
              />
              <Text
                text={`FORMAS DE RECOPILACIÓN DE DATOS`}
                variant={"body1"}
                marginTop={35}
              />
              <Text
                text={`Automáticos: Procesos electrónicos que permiten registrar actividades y comportamientos generales de los usuarios dentro de su APP, pero no permiten su identificación personal. Ejemplos de esto son: cantidad de visitantes, horarios de ingreso, páginas más vistas, cantidad de páginas vistas.`}
                variant={"body2"}
              />
              <Text
                text={`Datos demográficos y de intereses: Datos estimativos como edad, sexo e intereses, obtenidos a través de los análisis de navegación, remarketing y de funciones de informes publicitarios realizados por Google Analytics sobre aquellas cuentas de usuarios que lo autorizan en sus respectivos navegadores. `}
                variant={"body2"}
              />
              <Text
                text={`De ingreso directo: Solicitudes directas de información a los visitantes o usuarios de la  APP a través de formularios, botones de selección o afines, que permitan recolectar datos tales como nombre, rut, correo electrónico, ocupación u otros datos personales.`}
                variant={"body2"}
              />
              <Text
                text={`USO DE LA INFORMACIÓN`}
                variant={"body1"}
                marginTop={35}
              />
              <Text
                text={`Los datos personales de los usuarios serán utilizados únicamente para el cumplimiento de los fines indicados en el (los) formulario (s) correspondiente (s). En ellos se informará sobre las específicas finalidades para las que se recogen los datos. Si en algún caso llegara a requerirse un tratamiento distinto de lo indicado deberá quedar registro del consentimiento del titular.`}
                variant={"body2"}
              />
              <Text
                text={`Los datos personales que los titulares revelen en sistemas abiertos al público, como redes sociales o foros, serán almacenados junto al resto de contenidos, con el fin de permitir el funcionamiento, mantenimiento y transparencia de dichos sistemas y las contribuciones en él realizadas. En ningún caso estos datos serán tratados por Rancagua si existe o sus funcionarios con fines diferentes, sin solicitar el previo consentimiento de su titular.`}
                variant={"body2"}
              />
              <Text
                text={`Los datos personales recogidos a través de su APP serán almacenados únicamente durante el tiempo necesario para garantizar la prestación del servicio o el cumplimiento de la finalidad para la que fueron recabados.`}
                variant={"body2"}
              />
              <Text
                text={`Utilización con fines estadísticos: Los datos recogidos a través de la APP podrán ser conservados y tratados con fines estadísticos, siempre y cuando sea imposible la identificación de sus titulares.`}
                variant={"body2"}
              />
              <Text
                text={`COMUNICACIONES DE A TERCEROS`}
                variant={"body1"}
                marginTop={35}
              />
              <Text
                text={`Los datos recabados por Rancagua si existe a través de la APP no se comunicarán a terceros, salvo que:`}
                variant={"body2"}
              />
              <Text
                text={`1.	Dicha comunicación sea necesaria para la prestación del servicio solicitado, y siempre que ese tercero cumpla con las garantías necesarias sobre protección de datos.`}
                variant={"body2"}
              />
              <Text
                text={`2.	Se trate de datos estadísticos, agregados y anónimos donde no sea posible la identificación directa o indirecta de los titulares de dichos datos.`}
                variant={"body2"}
              />
              <Text
                text={`3.	Se realice en cumplimiento de un mandato legal o un requerimiento judicial o en los casos en que sea necesario para garantizar la seguridad nacional o el interés público.`}
                variant={"body2"}
              />
              <Text
                text={`DERECHOS USUARIOS`}
                variant={"body1"}
                marginTop={35}
              />
              <Text
                text={`El usuario podrá en todo momento ejercer los derechos otorgados por la ley Nº 19.628 sobre protección de la vida privada y sus modificaciones posteriores. En específico, podrá:`}
                variant={"body2"}
              />
              <Text
                text={`•	Tener acceso a los datos relativos a su persona, su procedencia y destinatario, el propósito del almacenamiento y la individualización de las personas u organismos a los cuales sus datos se hayan transmitido.`}
                variant={"body2"}
              />
              <Text
                text={`•	Solicitar se modifiquen sus datos personales cuando ellos no sean correctos o no estén actualizados.`}
                variant={"body2"}
              />
              <Text
                text={`•	Solicitar la eliminación o cancelación de los datos entregados cuando así lo desee, en tanto hayan dejado de ser necesarios o pertinentes para la finalidad para la cual hubieran sido recabados o registrados.`}
                variant={"body2"}
              />
              <Text
                text={`TITULARIDAD DE DERECHOS  `}
                variant={"body1"}
                marginTop={35}
              />
              <Text
                text={`Pertenecen a Rancagua si existe todos los derechos, incluidos los de propiedad intelectual, respecto de los contenidos, formularios o publicaciones de cualquier índole que aparezcan en su APP, que hayan sido realizadas por funcionarios de esta Institución o por terceros contratados por ella.`}
                variant={"body2"}
              />
            </Box>
          )}
        </Box>
      </Grid>
    </ThemeProvider>
  );
};

export default PersonalData;
