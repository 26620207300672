// import React, {useEffect, useState} from "react";
import React, {useEffect, useState} from 'react';

import Header from "../../globalComponents/Header";
import {
    Box,
    Card,
    Typography,
    CardContent,
    CardActions,
    Button,
    IconButton,
    Avatar,
    CircularProgress,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {colors} from "../../assets/css/colors";
import moment from "moment";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Image from "mui-image";

import {useSelector, useDispatch} from "react-redux";
import {getAllComments, updateComment} from "../../store/slices/temp";

const Item = ({data, handleUpdate, selectItem}) => {
    const dispatch = useDispatch();
    const {isLoading} = useSelector((state) => state.app);

    const handleUpdateComment = async (newStatus) => {
        const response = await dispatch(
            updateComment({status: newStatus, id: data.id})
        );

        // console.log(response);
        if (response.success) handleUpdate(); //await dispatch(getAllComments());
    };

    const handleSelectItem = (item) => {
        selectItem(item);
    };

    return (
        <Card sx={{m: 1}}>
            <CardContent sx={{p: 1, pl: 2, pr: 2, mt: 1}}>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Box marginRight={2}>
                        <div
                            onClick={() =>
                                handleSelectItem(
                                    `https://mas.cangurodigital.cl/files/avatar/${data.user.avatar}`
                                )
                            }
                        >
                            <Avatar
                                sx={{
                                    width: 70,
                                    height: 70,
                                    border: "0.7px solid black",
                                }}
                                src={`https://mas.cangurodigital.cl/files/avatar/${data.user.avatar}`}
                            />
                        </div>
                    </Box>
                    <Box display="flex" flexDirection="column" flex={1}>
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Typography variant="h4">{data.user.name}</Typography>
                            <Typography textAlign={"right"}>
                                {moment(data.createdAt).format("DD/MM/YYYY hh:mm:ss")}
                            </Typography>
                        </Box>
                        <Typography variant="body1">{data.message}</Typography>
                    </Box>
                </Box>
            </CardContent>
            <CardActions>
                <Box display="flex" flexDirection="row" width={"100%"}>
                    <Box
                        marginRight={2}
                        marginLeft={1}
                        sx={{
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {data.status === 0 && (
                            <Typography
                                sx={{color: "gray", fontWeight: "bold"}}
                                fontSize={16}
                            >
                                Pendiente
                            </Typography>
                        )}
                        {data.status === 1 && (
                            <Typography
                                sx={{color: "green", fontWeight: "bold"}}
                                fontSize={16}
                            >
                                Aprobado
                            </Typography>
                        )}
                        {data.status === 2 && (
                            <Typography
                                sx={{color: "red", fontWeight: "bold"}}
                                fontSize={16}
                            >
                                Rechazado
                            </Typography>
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            // backgroundColor: "green",
                            width: "100%",
                            justifyContent: "end",
                            pl: 2,
                        }}
                    >
                        {(data.status === 0 || data.status === 2) && (
                            <IconButton
                                disabled={isLoading}
                                sx={{
                                    backgroundColor: "#16A20E",
                                    color: "white",
                                    "&:hover": {
                                        backgroundColor: "#0F7E09",
                                    },
                                    "&:disabled": {
                                        backgroundColor: "gray",
                                        color: "white",
                                    },
                                }}
                                variant={"contained"}
                                onClick={() => handleUpdateComment(1)}
                            >
                                <CheckIcon/>
                            </IconButton>
                        )}
                        {(data.status === 0 || data.status === 1) && (
                            <IconButton
                                disabled={isLoading}
                                sx={{
                                    backgroundColor: "#FE0000",
                                    color: "white",
                                    ml: 1,
                                    "&:hover": {
                                        backgroundColor: "#BD0000",
                                    },
                                    "&:disabled": {
                                        backgroundColor: "gray",
                                        color: "white",
                                    },
                                }}
                                variant={"contained"}
                                onClick={() => handleUpdateComment(2)}
                            >
                                <CloseIcon/>
                            </IconButton>
                        )}
                    </Box>
                </Box>
            </CardActions>
        </Card>
    );
};

export const Comments = () => {
    const {comments} = useSelector((state) => state.temp);

    const dispatch = useDispatch();
    const [statusFilter, setStatusFilter] = useState(0);
    const [showDialogImage, setShowDialogImage] = useState(false);
    const [selectedItem, setSelectedItem] = useState("");

    const dataToUse = () => {
        let dataReturn = comments;
        const MiVar = true;

        if (statusFilter !== null)
            dataReturn = dataReturn
                .filter((comment) => comment.status === statusFilter)
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        else
            dataReturn = dataReturn
                .filter(() => MiVar === true)
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        return dataReturn;
    };

    const handleChangeFilter = (newStatus) => {
        setStatusFilter(newStatus);
    };

    const handleSelectItem = (item) => {
        setShowDialogImage(true);
        setSelectedItem(item);
    };

    const init = () => {
        dispatch(getAllComments());
    };

    // const load = () => {
    //     dispatch(getAllComments());
    // }
    //
    // const init = useCallback(() => {
    //     // Tu lógica aquí
    //     console.log('Mi función se ejecutó');
    //     load()
    // }, []); // El segundo argumento es un array de dependencias, en este caso está vacío ya que no tiene dependencias


    useEffect(() => {
        init();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box m="20px" pb={1} sx={{backgroundColor: ""}}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title={"Comentarios"} subtitle="Mentenedor de comentarios"/>
            </Box>

            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={1}
            >
                <Button
                    variant={"contained"}
                    color={"error"}
                    sx={{
                        borderRadius: 50,
                        backgroundColor: statusFilter === 0 ? colors.primary : "gray",
                        "&:hover": {
                            backgroundColor: colors.primary,
                        },
                    }}
                    onClick={() => handleChangeFilter(0)}
                >
                    Pendientes
                </Button>
                <Button
                    variant={"contained"}
                    color={"error"}
                    sx={{
                        borderRadius: 50,
                        backgroundColor: statusFilter === 1 ? colors.primary : "gray",
                        "&:hover": {
                            backgroundColor: colors.primary,
                        },
                    }}
                    onClick={() => handleChangeFilter(1)}
                >
                    Aprobadas
                </Button>
                <Button
                    variant={"contained"}
                    color={"error"}
                    sx={{
                        borderRadius: 50,
                        backgroundColor: statusFilter === 2 ? colors.primary : "gray",
                        "&:hover": {
                            backgroundColor: colors.primary,
                        },
                    }}
                    onClick={() => handleChangeFilter(2)}
                >
                    Rechazadas
                </Button>
                <Button
                    variant={"contained"}
                    color={"error"}
                    sx={{
                        borderRadius: 50,
                        backgroundColor: statusFilter === null ? colors.primary : "gray",
                        "&:hover": {
                            backgroundColor: colors.primary,
                        },
                    }}
                    onClick={() => handleChangeFilter(null)}
                >
                    Todas
                </Button>
            </Box>
            {false && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height={200}
                    sx={{backgroundColor: ""}}
                >
                    <CircularProgress sx={{color: colors.primary}}/>
                </Box>
            )}
            {true && (
                <Box
                    sx={{
                        maxHeight: "75vh",
                        overflowY: "auto",
                        mt: 1,
                        scrollbarWidth: "thin",
                        // border: "1px solid #ccc",
                        // p: 2,
                    }}
                >
                    {dataToUse().map((comment) => (
                        <Box key={comment.id}>
                            <Item
                                data={comment}
                                handleUpdate={() => init()}
                                selectItem={handleSelectItem}
                            />
                        </Box>
                    ))}
                </Box>
            )}

            <Dialog
                open={showDialogImage}
                onClose={() => setShowDialogImage(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Image
                        src={selectedItem}
                        duration={0}
                        height={"50vh"}
                        fit={"contain"}
                        showLoading={true}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowDialogImage(false)} variant="outlined">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
