import apiManager from "../../../helpers/apiManager";
import { setLoginUser } from "./userSlice";
import { setLoadingApp, setMessageApp, setCurrentPage } from "../app/appSlice";
import { genConfigHeader } from "../../../helpers/apiManager";

export const logIn = (formData) => {
  return async (dispatch, getState) => {
    try {
      // console.log(getState());

      dispatch(setLoadingApp(true));

      const data = {
        email: formData.username,
        password: formData.password,
        profileRequired: "admin",
      };

      //Consultamos a la API por usuario
      const response = await apiManager.post("/auth/signin", data);
      await new Promise((resolve) => setTimeout(resolve, 2000));

      if (!response.data.success) {
        await dispatch(
          setMessageApp({
            message: "Usuario y/o contraseña inválidos.",
            type: "error",
          })
        );
        dispatch(setCurrentPage("Dashboard"));
      } else {
        // console.log(response.data);
        dispatch(setLoginUser({ user: response.data, isLogged: true }));
      }
    } catch (error) {
      console.error(error);
      await dispatch(
        setMessageApp({
          message: "Ocurrió un error inesperado, intente nuevamente.",
          type: "error",
        })
      );
    } finally {
      dispatch(setLoadingApp(false));
    }
  };
};

export const logOut = () => {
  return async (dispatch, getState) => {
    dispatch(
      setLoginUser({
        user: { token: null, data: null },
        isLogged: false,
        isPublicUser: false,
      })
    );
    dispatch(setCurrentPage(""));

    // const state = getState();
    // console.log(state);

    return false;
  };
};

export const me = () => {
  return async (dispatch, getState) => {
    try {
      // console.log(getState());

      dispatch(setLoadingApp(true));

      const config = await genConfigHeader(getState());
      const response = await apiManager.get("/auth/me/1", config);

      // console.log(response);

      if (!response.data.success && response.status !== 401) {
        await dispatch(
          setMessageApp({
            message: response.data.message,
            type: "error",
          })
        );
        // dispatch(setCurrentPage("Dashboard"));
      } else {
        // console.log(response.data);
        // dispatch(setLoginUser({ user: response.data, isLogged: true }));
      }
    } catch (error) {
      console.error(error);
      await dispatch(
        setMessageApp({
          message: "Ocurrió un error inesperado, intente nuevamente.",
          type: "error",
        })
      );
    } finally {
      // console.log("finally me");
      dispatch(setLoadingApp(false));
    }
  };
};

export const closeAccount = (formData) => {
  return async (dispatch, getState) => {
    try {
      // console.log(getState());

      dispatch(setLoadingApp(true));

      //Consultamos a la API por usuario
      const response = await apiManager.post("/auth/closeAccount", formData);
      await new Promise((resolve) => setTimeout(resolve, 2000));

      if (!response.data.success) {
        await dispatch(
          setMessageApp({
            message: response.data.message,
            type: "error",
          })
        );
        return response.data;
        // dispatch(setCurrentPage("Dashboard"));
      } else {
        // console.log(response.data);
        // dispatch(setLoginUser({user: response.data, isLogged: true}));
        return response.data;
      }
    } catch (error) {
      console.error(error);
      await dispatch(
        setMessageApp({
          message: "Ocurrió un error inesperado, intente nuevamente.",
          type: "error",
        })
      );
    } finally {
      dispatch(setLoadingApp(false));
    }
  };
};

// export const hasRole=(role) => {
//     return async (dispatch, getState) => {
//         const state=getState();
//         return state.logginUser?.role === role;
//     }
// }
