import * as React from "react";
import { Route, Routes } from "react-router-dom";

import { ResetPwd } from "../scenes/rancagua/ResetPwdScreen";
import { NotFound } from "../scenes/global/NotFoundScreen";

import { PersonalData } from "../scenes/rancagua/tyc/PersonalDataScreen";
import { GeolocationPrivacy } from "../scenes/rancagua/tyc/GeolocationPrivacyScreen";
import { CloseAccount } from "../scenes/rancagua/CloseAccountScreen";

import Login from "../scenes/login";

function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />

      <Route path="/reset-password/:token" element={<ResetPwd />} />

      <Route path="/tyc/personaldata" element={<PersonalData />} />
      <Route path="/tyc/geolocationprivacy" element={<GeolocationPrivacy />} />
      <Route path="/close-account" element={<CloseAccount />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRouter;
