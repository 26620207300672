import * as React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

const Copyright = (props) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
      style={{ position: "relative" }}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://canguro.cl/" target={"_blank"}>
        CanguroDigital
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
      {/*<Backdrop open={true} style={{*/}
      {/*    position: "absolute",*/}
      {/*    color: "green",*/}
      {/*    backgroundColor: "rgba(240,230,0,0.4)",*/}
      {/*    zIndex: "drawer"*/}
      {/*}}>*/}
      {/*    <CircularProgress/>*/}
      {/*</Backdrop>*/}
    </Typography>
  );
};

export default Copyright;
