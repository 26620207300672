import "../index.css";
import * as React from "react";
import { ColorModeContext, useMode } from "../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import Topbar from "../scenes/global/Topbar";
import Sidebar from "../scenes/global/Sidebar";
import Dashboard from "../scenes/dashboard";

// import Clients from "../scenes/zonaSeguro/clients";
// import PreCharge from "../scenes/zonaSeguro/clients/preCharge";
// import Payments from "../scenes/zonaSeguro/payments";

import { Comments } from "../scenes/rancagua/CommentsScreen";
import { Locations } from "../scenes/rancagua/LocationScreen";
import { Users } from "../scenes/rancagua/UserScreen";

import { ResetPwd } from "../scenes/rancagua/ResetPwdScreen";
import { PersonalData } from "../scenes/rancagua/tyc/PersonalDataScreen";
import { GeolocationPrivacy } from "../scenes/rancagua/tyc/GeolocationPrivacyScreen";
import { CloseAccount } from "../scenes/rancagua/CloseAccountScreen";

import SnackbarComponent from "../globalComponents/SnackbarComponent";

import { useSelector } from "react-redux";
import { NotFound } from "../scenes/global/NotFoundScreen";

function PrivateRouter() {
  const [theme, colorMode] = useMode();
  const user = useSelector((state) => state.user);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar />
          <main className="content">
            <Topbar />
            <Routes>
              {user.isLogged && (
                <>
                  <Route path="/" element={<Dashboard />} />

                  <Route path="/locations" element={<Locations />} />
                  <Route path="/comments" element={<Comments />} />
                  <Route path="/users" element={<Users />} />

                  <Route path="/reset-password/:token" element={<ResetPwd />} />
                  <Route path="/tyc/personaldata" element={<PersonalData />} />
                  <Route path="/close-account" element={<CloseAccount />} />

                  <Route
                    path="/tyc/geolocationprivacy"
                    element={<GeolocationPrivacy />}
                  />

                  <Route path="*" element={<NotFound />} />
                </>
              )}
            </Routes>
            <SnackbarComponent />
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default PrivateRouter;
