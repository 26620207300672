import React, { useEffect, useState } from "react";
import {
  Paper,
  Box,
  Grid,
  TextField,
  CssBaseline,
  CircularProgress,
  Typography,
} from "@mui/material";
import Image from "mui-image";
import { LoadingButton } from "@mui/lab";

import PersonOffIcon from "@mui/icons-material/PersonOff";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";
import SnackbarComponent from "../../globalComponents/SnackbarComponent";

import logo_main from "../../assets/image/logo_main.png";
import login_background from "../../assets/image/login_background.jpg";
import { colors } from "../../assets/css/colors";

import { logOut, closeAccount } from "../../store/slices/user";

const defaultTheme = createTheme();

export const CloseAccount = () => {
  const { isLogged } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [finishProcess, setFinishProcess] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [responseValidate, setResponseValidate] = useState({ success: false });

  const init = async () => {
    // console.log("EFFECT", token);
    if (isLogged) await dispatch(logOut());
    // console.log("RESPONSE", response);
    setInitialLoading(false);
  };

  useEffect(() => {
    init();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event) => {
    setLoading(true);

    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    const data = {
      email: formData.get("email"),
      password: formData.get("password"),
    };

    // setFinishProcess(true);
    // console.log(data);

    const response = await dispatch(closeAccount(data));
    if (response.success) setFinishProcess(true);

    console.log("SUBMIT RESPONSE", response.success);

    setLoading(false);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        container
        component="main"
        // sx={{ height: "100vh", justifyContent: "center" }}
        sx={{
          height: "100vh",
          justifyContent: "center",
          backgroundImage: `url(${login_background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            my: 8,
            mx: 4,
            padding: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 15,
            width: "100%",
            maxWidth: 700,
          }}
          component={Paper}
          elevation={6}
        >
          <Image
            src={logo_main}
            duration={0}
            height={250}
            width={250}
            fit={"contain"}
            style={{ marginBottom: 0, backgroundColor: "" }}
          />
          {initialLoading && (
            <Box sx={{ mt: 20 }}>
              <CircularProgress style={{ color: colors.primary }} />
            </Box>
          )}

          {!initialLoading && finishProcess && (
            <Box sx={{ mt: 10 }}>
              <Typography
                variant="h4"
                color="text.secondary"
                align="center"
                style={{
                  position: "relative",
                  color: "black",
                }}
              >
                Cuenta cerrada
              </Typography>
              <Typography
                variant="h5"
                color="text.secondary"
                align="center"
                style={{
                  position: "relative",
                  color: "black",
                  marginTop: 30,
                }}
              >
                ¡Esperamos que el tiempo que estuviste fuese el mejor!
              </Typography>
            </Box>
          )}

          {!initialLoading && !finishProcess && (
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 5 }}>
              <SnackbarComponent />
              <Typography
                variant="h5"
                fontWeight={"bold"}
                color="text.secondary"
                align="center"
                style={{
                  position: "relative",
                  color: "red",
                }}
              >
                Si continuas cerrarás tu cuenta de forma definitiva
              </Typography>
              <Typography
                variant="h6"
                color="text.secondary"
                align="center"
                style={{
                  position: "relative",
                  color: "black",
                }}
              >
                ¿Estás seguro de continuar?
              </Typography>
              <TextField
                // disabled={isLoading}
                inputProps={{ minLength: 5 }}
                margin="normal"
                required
                fullWidth
                name="email"
                label="Email"
                type="email"
                id="email"
                sx={{ mt: 5 }}
              />
              <TextField
                // disabled={isLoading}
                inputProps={{ minLength: 5 }}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
              />
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 5,
                  mb: 2,
                  backgroundColor: "#F41010",
                  "&:hover": {
                    backgroundColor: "#BD0000",
                  },
                }}
                loading={loading}
                loadingPosition="end"
                endIcon={<PersonOffIcon />}
              >
                Cerrar cuenta
              </LoadingButton>
            </Box>
          )}
        </Box>
      </Grid>
    </ThemeProvider>
  );
};

export default CloseAccount;
