import axios from "axios";
import { store } from "../store";
import { setMessageApp } from "../store/slices/app";
import { setLoginUser } from "../store/slices/user";

export const HEADERS = {
  "Content-Type": "application/json",
  accept: "application/json",
  responseType: "json",
};

export const config = {
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: "http://localhost:8010/proxy",
  // baseURL: "http://localhost:8080/apiv2/",
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
    responseType: "json",
    "auth-token": "",
  },
};

export const genConfigHeader = async (state) => {
  const header = config;
  header.headers["auth-token"] = state.user.loginUserToken;
  header.headers["Content-Type"] = "application/json";
  header.headers["accept"] = "application/json";
  header.headers["responseType"] = "json";

  return header;
};

const ApiManager = () => {
  const Api = axios.create(config);

  Api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response) {
        const statusCode = error.response.status;
        const responseData = error.response.data;

        // Ejecutar cierta acción según el código de estado
        if (statusCode === 401) {
          //   console.log("Se requiere inicio de sesión");
          store.dispatch(
            setMessageApp({
              message: "Se requiere inicio de sesión.",
              type: "info",
            })
          );
          store.dispatch(
            setLoginUser({
              user: { token: null, data: null },
              isLogged: false,
              isPublicUser: false,
            })
          );

          return error.response;
        } else {
          console.log(`Error ${statusCode}: ${responseData.message}`);
          store.dispatch(
            setMessageApp({
              message: `${responseData.message}`,
              type: "error",
            })
          );

          return error.response;
        }
      } else {
        console.log("Error de red o servidor no disponible");
      }
      return Promise.reject(error);
    }
  );

  return Api;
};

export default ApiManager();
