import React from "react";
import { MenuItem } from "react-pro-sidebar";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
// import { tokens } from "../../../theme";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
// import "../Sidebar.css";

import { useSelector, useDispatch } from "react-redux";
import { setCurrentPage } from "../../../store/slices/app";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import FmdGoodIcon from "@mui/icons-material/FmdGood";

const Item = ({ title, to, icon, selected, setSelected }) => {
  return (
    <MenuItem
      component={<Link to={to} />}
      active={selected === title}
      icon={icon}
      onClick={() => setSelected(title)}
      className="pro-sidebar"
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

const PrivateElements = ({ collapsed }) => {
  // const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  const user = useSelector((state) => state.user);
  const { currentPage } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const changePage = async (page) => {
    await dispatch(setCurrentPage(page));
  };

  return (
    <>
      {((user.isLogged && !user.isPublicUser) ||
        (!user.isLogged && user.isPublicUser)) && (
        <>
          <Item
            title="Dashboard"
            to="/"
            icon={<HomeOutlinedIcon />}
            selected={currentPage}
            setSelected={changePage}
          />
          {/* <Typography
            variant="h6"
            color={colors.grey[300]}
            sx={{ m: collapsed ? "10px 5px 0 5px" : "10px 20px 0 20px" }}
            textAlign={collapsed ? "center" : "left"}
          >
            Administración
          </Typography> */}
          <Item
            title="Locaciones"
            to="/locations"
            icon={<FmdGoodIcon />}
            selected={currentPage}
            setSelected={changePage}
          />
          <Item
            title="Comentarios"
            to="/comments"
            icon={<ChatBubbleOutlineIcon />}
            selected={currentPage}
            setSelected={changePage}
          />
          <Item
            title="Usuarios"
            to="/users"
            icon={<Groups2OutlinedIcon />}
            selected={currentPage}
            setSelected={changePage}
          />
        </>
      )}
    </>
  );
};

export default PrivateElements;
