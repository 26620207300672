import { Box, IconButton, useTheme } from "@mui/material";
// import { useContext } from "react";
import { tokens } from "../../theme";
// import InputBase from "@mui/material/InputBase";
// import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
// import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
// import SearchIcon from "@mui/icons-material/Search";

import { useDispatch } from "react-redux";
import { logOut } from "../../store/slices/user";
import { useNavigate } from "react-router-dom";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // const colorMode = useContext(ColorModeContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeSession = async () => {
    // console.log("CERRANDO SESIÓN");

    await dispatch(logOut());
    return navigate("/");
  };

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      pt={1}
      pb={1}
      pr={2}
      backgroundColor={colors.primary[400]}
    >
      {/* SEARCH BAR */}
      {/* <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box> */}

      {/* ICONS */}
      <Box display="flex">
        {/* <IconButton onClick={ colorMode.toggleColorMode }>
                    { theme.palette.mode === "dark" ? (
                        <DarkModeOutlinedIcon/>
                    ) : (
                        <LightModeOutlinedIcon/>
                    ) }
                </IconButton> */}
        {/*<IconButton>*/}
        {/*    <NotificationsOutlinedIcon/>*/}
        {/*</IconButton>*/}
        {/*<IconButton>*/}
        {/*    <SettingsOutlinedIcon/>*/}
        {/*</IconButton>*/}
        <IconButton onClick={() => closeSession()}>
          <PersonOutlinedIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
