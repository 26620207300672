import React from "react";
import {
  Box,
  Grid,
  CssBaseline,
  Typography,
} from "@mui/material";
import Image from "mui-image";
import logo_main from "../../assets/image/logo_main.png";

export const NotFound = () => {
  return (
    <Grid
      container
      component="main"
      // sx={{ height: "100vh", justifyContent: "center" }}
      sx={{
        height: "100vh",
        justifyContent: "center",
      }}
    >
      <CssBaseline />
      <Box
        sx={{
          my: 8,
          mx: 4,
          padding: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Image
          src={logo_main}
          duration={0}
          height={250}
          width={250}
          fit={"contain"}
          style={{ marginBottom: 0, backgroundColor: "" }}
        />
        <Box
          component="form"
          // onSubmit={handleSubmit}
          sx={{ mt: 5 }}
        >
          <Typography
            variant="h3"
            color="text.secondary"
            align="center"
            style={{ position: "relative", color: "black", marginTop: 50 }}
          >
            Ups!
          </Typography>
          <Typography
            variant="h5"
            color="text.secondary"
            align="center"
            style={{ position: "relative", color: "black", marginTop: 20 }}
          >
            El contenido al que intentas ingresar no existe =(
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};
